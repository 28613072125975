module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"SceneLab - Online mockups made easy","short_name":"SceneLab","start_url":"/","background_color":"#ececec","theme_color":"#52a1ec","display":"standalone","icon":"assets/icon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"522f193a2c64957be82db25f6dc9822e"},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[{"resolve":"/opt/build/repo/node_modules/gatsby-remark-copy-linked-files","id":"80533a5b-ad2d-50ff-af51-726674869458","name":"gatsby-remark-copy-linked-files","version":"2.3.9","pluginOptions":{"plugins":[]},"nodeAPIs":[],"browserAPIs":[],"ssrAPIs":[]},{"resolve":"/opt/build/repo/node_modules/gatsby-remark-images","id":"cb549a50-247b-5a67-9604-892606f2acf7","name":"gatsby-remark-images","version":"3.3.16","pluginOptions":{"plugins":[],"maxWidth":1035,"showCaptions":["alt"],"quality":100,"withWebp":{"quality":100}},"nodeAPIs":[],"browserAPIs":["onRouteUpdate"],"ssrAPIs":[]},{"resolve":"/opt/build/repo/node_modules/gatsby-remark-prismjs","id":"30cfc2ac-c438-55c4-ab18-5170f000dc90","name":"gatsby-remark-prismjs","version":"3.5.8","pluginOptions":{"plugins":[],"classPrefix":"language-","inlineCodeMarker":null,"aliases":{}},"nodeAPIs":[],"browserAPIs":[],"ssrAPIs":[]}],"extensions":[".mdx",".md"],"gatsbyRemarkPlugins":["gatsby-remark-copy-linked-files",{"resolve":"gatsby-remark-images","options":{"maxWidth":1035,"showCaptions":["alt"],"quality":100,"withWebp":{"quality":100}}},{"resolve":"gatsby-remark-prismjs","options":{"classPrefix":"language-","inlineCodeMarker":null,"aliases":{}}}]},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1035,"showCaptions":["alt"],"quality":100,"withWebp":{"quality":100}},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-MZSQ7S7","gtmAuth":"P3AKKXqJZYBUxAEC5DaaDg","gtmPreview":"env-2"},
    }]
